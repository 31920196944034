import React from 'react';
import Layout from '../components/Layout';

const EventsPage = () => {
    return (
        <Layout>
            <h1>This is the events page!</h1>
        </Layout>
    );
};

export default EventsPage;
